<template>
  <div class="page-view">
    <CustomBanner
      title="Send Errand for Individual Runners."
      paragraph="Send Errand helps you to be your own boss and also work at your own pace to earn."
      appStorelink=""
      playStorelink=""
      :src="image"
    />
    <main id="how-it-works">
      <section>
        <div class="container">
          <div class="section-header">
            <h3>How It Works</h3>
            <p>Step by Step instructions on becoming a runner.</p>
          </div>
          <div class="row">
            <div class="col-md-12">
              <StepView :steps="steps" />
            </div>
          </div>
        </div>
      </section>
      <section class="bg-prime runner-p">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="infor-demo-view animate__animated animate__slideInLeft">
                <img
                  src="@/assets/img/runners/senderrand-download-image.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="infor-content">
                <h2>Download, Sign Up and Get Started</h2>
                <p>
                  Download Send Errand on Apple Store or Google Playstore and
                  Sign up with necessary details.
                </p>
                <div class="btn-set">
                  <button class="btn-app play-store">
                    <span class="-icon"><i class="play-store-icon"></i></span>
                  </button>
                  <button class="btn-app app-store">
                    <span class="-icon"><i class="app-store-icon"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <VideoSection />
    </main>
    <Footer />
  </div>
</template>

<script>
import CustomBanner from "../../../components/CustomBanner.vue";
import Footer from "../../../components/Footer.vue";
import StepView from "../../../components/StepView.vue";
import VideoSection from "../../../components/VideoSection.vue";

export default {
  components: { CustomBanner, Footer, StepView, VideoSection },
  data: () => {
    return {
      image: require("../../../assets/img/senderrrand-runners-banner.svg"),
      steps: [
        {
          count: 1,
          header_title: "Download the Runners App",
          desc: "Download Our Mobile App for free on Google Play Store and Apple Store.",
          sub_desc: "Start earning big, download for Free!",
          img_url: require("../../../assets/img/runners/senderrand-runner-step-1.svg"),
          btn_title: "Register Your Business",
          btn_link: "",
          btn_app_store: "link",
          btn_play_store: "link",
        },
        {
          count: 2,
          header_title: "Register as a Runner",
          desc: "Go through our seamless registration processes, and become a Runner with ease.",
          sub_desc: "Start earning big, download for Free!",
          img_url: require("../../../assets/img/runners/senderrand-runner-step-2.svg"),
          btn_title: "",
          btn_link: "",
          btn_app_store: "",
          btn_play_store: "",
        },
        {
          count: 3,
          header_title: "Take Course and Complete Test.",
          desc: "Our Courses are to train you to be able to serve your Customers the right way. And we give a short test to ensure that you understood well.",
          sub_desc: "Start earning big, download for Free!",
          img_url: require("../../../assets/img/runners/senderrand-runner-step-3.svg"),
          btn_title: "",
          btn_link: "",
          btn_app_store: "",
          btn_play_store: "",
        },
        {
          count: 4,
          header_title: "Complete KYC",
          desc: "Upload at least, one valid means of Identification and a clear photograph of your self, and the deatils of your Guarantor.",
          sub_desc: "Start earning big, download for Free!",
          img_url: require("../../../assets/img/runners/senderrand-runner-step-4.svg"),
          btn_title: "",
          btn_link: "",
          btn_app_store: "",
          btn_play_store: "",
        },
        {
          count: 5,
          header_title: "Start Earning",
          desc: "Start receiving orders from Customers, start running errands and start earning.",
          sub_desc: "Start earning big, download for Free!",
          img_url: require("../../../assets/img/runners/senderrand-runner-step-5.svg"),
          btn_title: "",
          btn_link: "",
          btn_app_store: "link",
          btn_play_store: "link",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
</style>